<template>
  <div class="saleSampleSettlementQuantity">
    <h1>已结算数量统计</h1>
    <el-form :inline="true">
      <el-form-item label="到货日期">
        <el-date-picker v-model="searchParam.sampleDates" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" border height="calc(100vh - 310px)">
      <el-table-column prop="sampleDate" label="日期" show-overflow-tooltip>
        <template #default="scope">
          <el-text>{{ util.parseTime(scope.row.sampleDateGe, '{y}-{m}-{d}') }}</el-text>
          <el-text v-if="scope.row.sampleDateGe != scope.row.sampleDateLe">{{ '~' + util.parseTime(scope.row.sampleDateLe, '{y}-{m}-{d}') }}</el-text>
        </template>
      </el-table-column>
      <el-table-column v-for="custom in show.customList" :key="custom.id" :label="custom.value" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.custom2SampleQuantity[custom.value]) }}</el-text>
        </template>
      </el-table-column>
      <el-table-column label="合计" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.custom2SampleQuantity['合计']) }}</el-text>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import util from '../../utils/util'
import math from '../../utils/math'

const data = reactive({
  list: [],
})

const show = reactive({
  customList: [],
})
api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
  commitFindList()
})

let lastM25 = new Date()
lastM25.setMonth(lastM25.getMonth() - 1)
lastM25.setDate(25)
let now24 = new Date()
now24.setDate(24)
const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.sampleDates = [lastM25, now24]
}
resetSearchParam()

const commitFindList = () => {
  const params = {}
  if (searchParam.sampleDates && searchParam.sampleDates.length == 2) {
    params.sampleDateGe = util.parseTime(searchParam.sampleDates[0], '{y}-{m}-{d}')
    params.sampleDateLe = util.parseTime(searchParam.sampleDates[1], '{y}-{m}-{d}')
  } else {
    ElMessage.error("请选择日期")
    return
  }
  params.customList = show.customList.map(x => x.value)
  api.post('/backend/saleSample/settlementQuantity', params).then(res => {
    data.list = res.list
    if (data.list.length > 0) {
      data.list[0].sampleDateGe = params.sampleDateGe
      data.list[0].sampleDateLe = params.sampleDateLe
    }
  })
}

</script>

<style lang="less"></style>